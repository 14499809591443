import { getApiUrl } from "sutro-common";

import { StudioError } from "~/lib/studio-error";

/** @todo:  make this more resiliant */

// prettier-ignore
const API_HOSTNAME =   import.meta.env.SUTRO_ENV === "production"   ? "create.withsutro.com"
                    :  import.meta.env.SUTRO_ENV === "staging"      ? "staging.withsutro.com"
                    :  import.meta.env.SUTRO_ENV === "development"  ? "local.withsutro.com"
                    :                                                 "invalid";

// prettier-ignore
const PREVIEW_HOSTNAME =   import.meta.env.SUTRO_ENV === "production"   ? "studiopreview.withsutro.com"
                        :  import.meta.env.SUTRO_ENV === "staging"      ? "studiopreview.staging.withsutro.com"
                        :  import.meta.env.SUTRO_ENV === "development"  ? "studiopreview.local.withsutro.com"
                        :                                                 "invalid";

if (API_HOSTNAME === "invalid") {
  throw new StudioError(`Invalid SUTRO_ENV setting`, {
    context: { STUDIO_ENV: import.meta.env.SUTRO_ENV },
  });
}

export default {
  apiRoot: getApiUrl(`https://${API_HOSTNAME}`),
  analyticsApiRoot: `https://${API_HOSTNAME}`,
  previewUrl: `https://${PREVIEW_HOSTNAME}`,
  shouldShowAppImmediately: import.meta.env.NODE_ENV !== "production",
  isDev: import.meta.env.NODE_ENV !== "production",
  isProdTest: import.meta.env.PROD_TEST !== undefined,
  sentryDsn: import.meta.env.SUTRO_SENTRY_DSN,
  otelServiceName: import.meta.env.STUDIO_OTEL_SERVICE_NAME ?? "sutro-studio2",
  otelEndpoint: import.meta.env.OTEL_EXPORTER_OTLP_ENDPOINT,
  intercomAppId: import.meta.env.INTERCOM_APP_ID,
  posthogKey: import.meta.env.REACT_APP_PUBLIC_POSTHOG_KEY,
  posthogHost: import.meta.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};
